type AgentResponse = models.server.api.international.agent.AgentResponse
type ApiQueryBody = models.server.api.international.ApiQueryBody
type AggregatedAgents =
  models.server.api.international.predictive.AggregatedAgents
type ApiResponse = models.server.api.international.ApiResponse

type Query = { keyword?: string; city?: string; country?: string }
type ApiParams = Record<string, string | number | ApiFilterEntry | undefined>
type ApiFilterEntry = Record<
  string,
  {
    value: string[]
    last: boolean
  }
>

export default function () {
  const fetchCountriesCities = async (query_string = '') => {
    const url = `/api/international/agent`
    const request: ApiQueryBody = {
      id: 'is-prefill-agents',
      params: { query_string }
    }

    const response = await $fetch<ApiResponse>(url, {
      method: 'POST',
      body: request
    })

    const data = response.aggregations as AggregatedAgents | undefined
    const countries = ref<string[]>([])
    const cities = ref<string[]>([])

    if (query_string === '') {
      countries.value =
        data?.countries?.buckets
          .map((b) => b.key)
          .filter((key) => key.length > 1) || []
    }

    cities.value =
      data?.cities?.buckets.map((b) => b.key).filter((key) => key.length > 1) ||
      []

    return { countries: countries.value, cities: cities.value }
  }

  async function fetchAgents(
    type: 'agents' | 'suggestions' | 'prefill',
    query?: Query,
    page = 1,
    pageSize = 20
  ) {
    const idMap = {
      agents: 'is-search-agents-v1.0',
      suggestions: 'is-suggest-agents-v1.0',
      prefill: 'is-prefill-agents'
    }

    const id = idMap[type]
    const params = getParams(query, page, pageSize)
    const url = `/api/international/agent`
    const apiResponse = await $fetch<ApiResponse>(url, {
      method: 'POST',
      body: {
        id,
        params
      }
    })

    return {
      results: (apiResponse?.hits?.hits ?? []) as AgentResponse[],
      total: apiResponse?.hits?.total?.value ?? 0
    }
  }

  async function fetchSuggestions() {
    const { results } = await fetchAgents('suggestions')

    return results.map((r) => ({
      name: r._source.name,
      id: r._id
    }))
  }

  function getParams(query: Query | undefined, page = 1, pageSize = 20) {
    const { keyword, city, country } = query || {}

    const size = Math.max(1, pageSize)
    // const from = Math.max(0, page - 1) * size
    const from = Math.max(0, page - 1) * size
    const params: ApiParams = {
      query_string: keyword ?? '',
      from,
      size,
      filters: getFilters({ country, city })
    }

    return params
  }

  function getFilters(value?: Record<string, unknown | unknown[]>) {
    if (!value) return []

    const entries = Object.entries(value).filter(([_k, v]) => v != undefined)

    return entries.reduce(
      (o, [k, v], i) => ({
        ...o,
        [k]: {
          value: Array.isArray(v) ? v : [v],
          last: i === entries.length - 1
        }
      }),
      {}
    )
  }

  return {
    fetchAgents,
    fetchCountriesCities,
    fetchSuggestions
  }
}
