import { defineStore } from 'pinia'

type SubjectArea = models.server.api.international.subjectArea.SubjectArea
type SubjectAreaTisims =
  models.server.api.international.subjectArea.TisimsSubjectArea

interface State {
  sitecoreSubjectAreas: SubjectArea[]
  tisimsSubjectAreas: SubjectAreaTisims[]
  locations: string[]
}

export const useIntSubjectAreaLocationStore = defineStore(
  'intSubjectAreaLocation',
  {
    state: (): State => ({
      sitecoreSubjectAreas: [],
      tisimsSubjectAreas: [],
      locations: []
    }),

    actions: {
      async fetchByTisims() {
        const { fetchSubjectAreasLocations } = useIntCourseSearch()

        if (this.tisimsSubjectAreas.length && this.locations.length) {
          return {
            subjectAreas: this.tisimsSubjectAreas,
            locations: this.locations
          }
        }

        const { subjectAreas, locations } = await fetchSubjectAreasLocations()

        this.tisimsSubjectAreas = subjectAreas
        this.locations = locations

        return { subjectAreas, locations }
      },
      async fetchBySitecore() {
        const { getAllSubjectAreas } = useInternationalApi()

        if (this.sitecoreSubjectAreas.length) return this.sitecoreSubjectAreas

        const { data } = await getAllSubjectAreas()
        this.sitecoreSubjectAreas = data?.subjectAreas?.children || []
      }
    },

    getters: {
      getSubjectAreaBySitecore: (state) => (subjectArea: string) => {
        return state.sitecoreSubjectAreas.find(
          (x) => x.name === subjectArea || x?.tisimsName?.value === subjectArea
        )
      },
      getSubjectAreasByTisims:
        (state) => (subjectArea: string, tisimsName?: string) => {
          return state.tisimsSubjectAreas.find(
            (x) => x.name === subjectArea || x.name === tisimsName
          )
        }
    }
  }
)
